<template>
    <div class="game-info space-y-6">
        <div class="card bg-white shadow-md p-5 rounded-md">
            <h1 class="text-2xl font-bold mb-4">ゲーム紹介</h1>
            <p class="text-lg">
                無料タイピングAnimalタイピングへようこそ！このゲームは、動物の名前を高速に打つためのタイピングゲームです。あなたのタイピングスキルを上達させ、同時に様々な動物の名前を絵と一緒に覚えることができます。
                私たちのゲームは他のタイピングゲームと一線を画しています。大文字やスペースキーもチェックの対象となり、タイピングミスがあった場合、バックスペースキーを使って自己修正する必要があります。これは、実際のパソコン使用環境を反映した設計になっています。
                例えば、"Lion"という単語をタイピングする際、正しくは"L"を大文字で始め、その後に小文字の"ion"を打つ必要があります。間違えて小文字の"l"を打った場合は、バックスペースキーを使って修正し、再度正しい文字を打つことになります。
                このように、Animalタイピングは楽しみながら真剣にタイピングスキルを磨くことができるゲームです。ぜひ挑戦してみてください！
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name:'aboutMe',
}
</script>

<style>
.game-info{
    margin: 0 auto;
    max-width: 800px;
    padding: 2rem 0;
}
</style>
